@import url(https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap);
*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
  font-size: 19px;
}

::selection {
  background: #d93829;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  background: #D9D9D9;
  overflow: hidden;
  font-family: 'Karla', sans-serif;
  color: #545454;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  line-height: 1.4rem;
}

a {
  color: #545454;
  text-decoration: none;
}

a:focus, 
a:hover {
  color: #d93829;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.loading {
  padding: 10px;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.2rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__links {
  margin: 1rem 0;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.2rem;
}

/* .bottom-left {
  padding: 1.5rem;
  transform: translate3d(0, -100%, 0) !important;
} */

.center {
  padding: 1.5rem;
  -webkit-transform: translate3d(50, 100%, 0) !important;
          transform: translate3d(50, 100%, 0) !important;
}



@media screen and (min-width: 53em) {
	body {
		overflow: hidden;
  }
  

	.frame {
    display: grid;
    height: 100vh;
    padding: 1rem 2.7rem;
    pointer-events: none;
		align-content: space-between;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links'
							'... ...'
							'... nav';
	}
	.frame__title {
    grid-area: title;
	}
	.frame__links {
		grid-area: links;
    justify-self: end;
    margin: 0;
  }
  .frame__link {
    pointer-events: auto;
  }
  .frame__nav {
    grid-area: nav;
		justify-self: end;
  }
  /* .bottom-left {
    height: 100%;
    width: 100%;
  } */

}



